<template>
    <div>
        <template v-if="content_local">
            <iframe :src="content_local" alt="Image" id="inputFileImage" class="mb-4">
            </iframe>
        </template>

        <b-form-file v-model="input_text" :placeholder="placeholder" :browse-text="getTrad('model.placeholder.logo_button_text')" :drop-placeholder="getTrad('model.placeholder.logo_drop_placeholder')">
            <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="primary">{{ names[0] }}</b-badge>
            </template>
        </b-form-file>
        <b-button class="mt-3" block @click="deleteElement">{{ $t('action.supprimer') }}</b-button>

        
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "FileInput",
        mixins: [Navigation],
        props:[
            'z_id',
            'content',
            'placeholder'
        ],
        data() {
            return {
                input_text: null,
                content_local: null,
                events_tab: {
                    'inputs::resetChecked': this.resetCheck
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                this.content_local = this.content
            },
            resetCheck() {
                this.input_text = null;
                this.content_local = null;
            },
            deleteElement(){
                this.resetCheck()
                this.$emit('change', { key: this.z_id, val: null, type: 'FileInput' })
            }
        },
        watch: {
            'input_text' (val) {
                this.$emit('change', { key: this.z_id, val: val, type: 'FileInput' })
            }
        }
    }
</script>
